import React from "react";
import { articles } from "../data";

export default function PublicationsContent() {
  return (
    <section className="uk-section threatments-content">
      <div className="uk-container">
        <div className="uk-child-width-1-2@m cards uk-grid-large" data-uk-grid>
          {articles.map((item, index) => (
            <div key={index} className="card-wrapper">
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <div
                  className="card"
                  style={{ display: "flex", marginBottom: "20px" }}
                >
                  {/* Content */}
                  <div className="card-content">
                    <div className="title">{item.name}</div>
                    <div className="desc">{item.title}</div>
                    <a
                      href={item.pdf}
                      download={`${item.name}.pdf`}
                      className="uk-button uk-button-default"
                      style={{ marginTop: "10px" }}
                    >
                      Download PDF
                    </a>
                  </div>
                  <div
                    className="card-image"
                    style={{
                      position: "relative",
                      width: "200px",
                      height: "200px",
                      marginLeft: "20px",
                    }}
                  >
                    <img
                      src={item.image} // Resmin URL'si veya yolu (örneğin, 'a.jpg' gibi)
                      alt="Image" // Erişilebilirlik için alternatif metin
                      width="100%"
                      height="100%"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        transition: "transform 0.2s ease-in-out",
                        transformOrigin: "center center",
                        zIndex: 1,
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(5)";
                        e.currentTarget.style.zIndex = 2;
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.zIndex = 1;
                      }}
                    />
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
