import React from 'react'
import ContactForm from '../components/Contact/ContactForm'
import ContactIntroduction from '../components/Contact/ContactIntroduction'
import ContactLocations from '../components/Contact/ContactLocations'

export default function ContactScreen() {
    return (
        <>
            <ContactIntroduction/>
            <ContactLocations/>
            <ContactForm/>
        </>
    )
}
