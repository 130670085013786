
import ResearchContent from "../components/ResearchTeaching/ResearchContent";
import ResearchIntroduction from "../components/ResearchTeaching/ResearchIntroduction";
import TeachingExperience from "../components/ResearchTeaching/TeachingExperience";
import TeachingExperienceContent from "../components/ResearchTeaching/TeachingExperienceContent";


import React, { useEffect, useState } from 'react';


export default function ResearchScreen() {
  const [fontSize, setFontSize] = useState("18px");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) {
        setFontSize("15px");
      } else {
        setFontSize("18px");
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div>
        <div uk-grid>
          <div className="uk-width-auto@m uk-flex-last@m uk-flex uk-flex-center uk-top uk-margin-large-top">
            <ul
              className="uk-tab "
              uk-tab="connect: #component-tab; animation: uk-animation-fade"
            >
              <li>
                <a href="#" style={{ fontSize }}>Research Projects</a>
              </li>
              <li>
                <a href="#" style={{ fontSize }}>Teaching Experience</a>
              </li>
            </ul>
          </div>
          <div className="uk-width-expand@m">
            <div id="component-tab" className="uk-switcher">
              <div>
                <ResearchIntroduction />
                <ResearchContent />
              </div>
              <div>
                <TeachingExperience />
                <TeachingExperienceContent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
