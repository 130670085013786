/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Name from '../Name'
import Title from '../Title'
import Email from '../Email'
import SocialNav from '../SocialNav'
import MobileNavbar from './MobileNavbar'
import NavbarItems from './NavbarItems'

export default function Navbar() {
    return (
        <header>
            <nav className="uk-navbar-container uk-flex-around bottom-line uk-visible@m" data-uk-navbar data-uk-sticky>
                <NavbarItems/>
            </nav>
            <nav className="uk-navbar-container bottom-line uk-hidden@m" data-uk-navbar>
                <div className="uk-navbar-center">
                    <div className="uk-navbar-item">
                        <a uk-icon="menu" uk-toggle="target: #offcanvas-nav-primary"></a>
                    </div>
                    <MobileNavbar/>
                </div>
            </nav>
            <div className='uk-background-secondary'>
                <div className="uk-grid-large uk-child-width-1-3@m uk-text-center uk-flex-middle uk-flex-center uk-padding bottom-line uk-text-emphasis" data-uk-grid>
                    <div className="uk-visible@m">
                    </div>
                    <div className='uk-light'>
                        <h1 className="uk-margin-remove uk-text-bold"><Name/></h1>
                        <h4 className="uk-margin-small"><Title/></h4>
                    </div>
                    <div className="uk-visible@m uk-light">
                        <Email/>
                        <div className="uk-flex uk-flex-center uk-margin-small-top">
                            <SocialNav/>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
