import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Email from '../Email'
import { navItems } from '../data'

export default function MobileNavbar() {
    useEffect(() => {
        let items = document.querySelectorAll('#offcanvas-nav-primary ul li a');
        items.forEach((item) => {
            item.addEventListener('click', () => {
                let closeButton = document.querySelector('#offcanvas-nav-primary .uk-offcanvas-close');
                closeButton.click();
            })
        })
    })
    return (
        <div id="offcanvas-nav-primary" uk-offcanvas="overlay: true; filp:true;">
            <div className="uk-offcanvas-bar uk-flex uk-flex-column">
                <button className="uk-offcanvas-close" type="button" data-uk-close></button>
                <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">

                    {navItems.map((item, index) =>
                       <li key={index}><Link to={item.path}>{item.name}</Link></li>
                    
                    )}
                    <li className="uk-nav-divider uk-margin-large uk-margin-remove-bottom"></li>
                    <li className="uk-margin-large uk-margin-remove-bottom">
                        <p><Email/></p>
                    </li>
                </ul>
            </div>
        </div>
    )
}
