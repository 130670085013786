import React from 'react'

export default function Footer() {
    return (
        <footer>
            <section className="uk-section uk-section-xsmall" style={{background:'#37474F'}}>
                <div className="footer-divider uk-visible@m"/>
                {/*Mobile*/}
                <div className="uk-container uk-width-1-1 uk-margin-auto uk-text-center uk-grid-collapse uk-hidden@m" data-uk-grid>
                    <div className="uk-width-1-1 uk-text-small">
                        <div className="uk-light">Copyright 2024 © All rights reserved.</div>
                    </div>
                    <div className="uk-width-1-1 uk-margin uk-light">
                    Designed by&nbsp;<a href="https://www.linkedin.com/in/merve-bayer/"  target='_blank' rel="noopener noreferrer" >Merve Bayer
                    </a>
                    </div>
                </div>
                {/*Desktop*/}
                <div className="uk-container uk-width-1-1 uk-margin-auto uk-grid-collapse uk-flex-middle uk-visible@m" data-uk-grid>
                    <div className="uk-width-expand@m uk-flex uk-flex-column uk-flex-left uk-padding uk-padding-remove-vertical uk-padding-remove-right uk-text-small">
                        <div className="uk-light">Copyright 2024 © All rights reserved.</div>
                    </div>
                    <div className="uk-width-auto@m uk-light uk-flex uk-flex-right uk-padding uk-padding-remove-vertical uk-padding-remove-left uk-text-small">
                    Designed by&nbsp;<a href="https://www.linkedin.com/in/merve-bayer/"  target='_blank' rel="noopener noreferrer" >Merve Bayer
                                        </a>
                    </div>
                </div>
            </section>
        </footer>
    )
}
