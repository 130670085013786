import React from "react";
import { additionalInfo } from "../data";

export default function AdditionalInformation() {
  const renderDescriptionDetail = (desc) => {
    return desc.map((item, index) => (
      <li key={index}>
        {item.date ? (
          <strong className="text-purple opacity-85">{item.date}:</strong>
        ) : null}{" "}
        {item.descriptionDetail}
      </li>
    ));
  };
  return (
    <section className="uk-section uk-background-secondary ">
      <div className="uk-container">
        <div
          className="uk-grid-collapse uk-flex-middle uk-flex-center"
          data-uk-grid
        >
          <div className="uk-flex uk-flex-center uk-light uk-margin-medium-bottom">
            <h1 className="uk-margin-remove">Additional Information</h1>
          </div>

          <div className="relative grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 lg:col-span-2 md:col-span-3 md:row-span-2">
            {additionalInfo.map((info, index) => (
              <div
                key={index}
                className="rounded-[22px] p-4 sm:p-10 dark:bg-slate-900 h-[12rem] uk-light"
              >
               <h5 style={{ fontWeight: 'bold' }}>{info.title}</h5>
                <ul className=" font-sans text-sm text-neutral-600 dark:text-neutral-400">
                  {renderDescriptionDetail(info.desc)}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
