import React from "react";
import Email from '../../Email'
export default function ContactLocationsHeader() {
  return (
    <section className="uk-section uk-background-muted">
      <div className="uk-container">
        <div
          className="uk-margin-auto uk-child-width-1-1 uk-width-1-2@m uk-flex-center uk-text-center uk-grid-collapse"
          data-uk-grid
        >
          <div>
            <div class="uk-card uk-card-default uk-card-body">
              <div className="uk-h1 uk-margin-remove">Contact Information</div>
              <ul class="uk-list">
            
                <li><Email/></li>
        
       
           
              </ul>
              <div class="uk-margin">
                <a
                  href="https://www.linkedin.com/in/mert-bayer-aa631359/en"
                  target="_blank"
                  class="uk-icon-button uk-margin-small-right"
                  uk-icon="linkedin"
                  rel="noopener noreferrer"
                ></a>
                <a
                  href="https://scholar.google.com.tr/citations?user=p-DN1psAAAAJ&hl=en&oi=ao"
                  target="_blank"
                  class="uk-icon-button uk-margin-small-right"
                  rel="noopener noreferrer"
                >
                  <svg
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M256 411.12L0 202.667 256 0z" fill="#4285f4" />
                      <path d="M256 411.12l256-208.453L256 0z" fill="#356ac3" />
                      <circle
                        cx="256"
                        cy="362.667"
                        fill="#a0c3ff"
                        r="149.333"
                      />
                      <path
                        d="M121.037 298.667c23.968-50.453 75.392-85.334 134.963-85.334s110.995 34.881 134.963 85.334H121.037z"
                        fill="#76a7fa"
                      />
                    </g>
                  </svg>
                </a>
                <a
                  href="https://www.researchgate.net/profile/Mustafa-Bayer"
                  target="_blank"
                  class="uk-icon-button uk-margin-small-right"
                  rel="noopener noreferrer"
                >
                  <svg
                    fill="#fff"
                    height="25"
                    width="25"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M 5 5 L 5 27 L 27 27 L 27 5 L 5 5 z M 7 7 L 25 7 L 25 25 L 7 25 L 7 7 z M 19.164062 10.001953 C 17.881062 10.001953 17.441406 10.919156 17.441406 11.535156 L 17.441406 13.169922 C 17.441406 13.999922 17.8935 14.792969 19.0625 14.792969 C 21.0245 14.790969 20.787109 13.306391 20.787109 12.275391 L 19.253906 12.275391 L 19.253906 12.816406 L 20.158203 12.816406 C 20.158203 13.621406 19.781453 14.162109 19.064453 14.162109 C 18.498453 14.162109 18.171875 13.671188 18.171875 12.992188 L 18.171875 11.634766 C 18.171875 11.005766 18.762062 10.642578 19.164062 10.642578 C 19.881062 10.642578 20.15625 11.271484 20.15625 11.271484 L 20.697266 10.90625 C 20.697266 10.90625 20.434062 10.001953 19.164062 10.001953 z M 13.583984 13.091797 C 12.678984 13.091797 11.296953 13.178906 10.001953 13.128906 L 10.001953 13.53125 C 10.781953 13.68225 11.107422 13.606281 11.107422 14.738281 L 11.107422 20.269531 C 11.107422 21.413531 10.780953 21.325563 10.001953 21.476562 L 10.001953 21.892578 C 10.378953 21.879578 11.031266 21.841797 11.697266 21.841797 C 12.326266 21.841797 13.144094 21.867578 13.496094 21.892578 L 13.496094 21.476562 C 12.490094 21.338562 12.1875 21.451531 12.1875 20.269531 L 12.1875 17.931641 C 12.5275 17.956641 12.817531 17.955078 13.269531 17.955078 C 14.124531 19.489078 14.94125 20.634781 15.40625 21.175781 C 16.24825 22.193781 17.594875 22.043578 17.921875 21.892578 L 17.921875 21.515625 C 17.418875 21.514625 16.914781 21.175437 16.550781 20.773438 C 15.934781 20.107437 15.104781 19.025641 14.425781 17.806641 C 15.557781 17.529641 16.400391 16.461297 16.400391 15.404297 C 16.400391 13.820297 15.179984 13.091797 13.583984 13.091797 z M 13.320312 13.730469 C 14.502313 13.730469 15.205078 14.346516 15.205078 15.478516 C 15.204078 16.586516 14.450359 17.326172 13.193359 17.326172 C 12.728359 17.326172 12.5145 17.314063 12.1875 17.289062 L 12.1875 13.767578 C 12.5145 13.729578 12.942312 13.730469 13.320312 13.730469 z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
