import React from 'react'
import HomeAbout from '../components/Home/HomeAbout'
import AboutEducation from '../components/About/AboutEducation'
import AdditionalInformation from '../components/Home/AdditionalInformation'
import Awards from '../components/Home/Awards'

export default function HomeScreen() {
    return (
        <>
            {/* <HomeIntroduction/> */}
            <HomeAbout/>
            <div className="bottom-line" data-uk-grid>
            </div>
            <AboutEducation/>
             <AdditionalInformation />
             <Awards />
     
        </>
    )
}
