import React from 'react'
import { mail } from './data'

export default function Email() {
    return (
        <div>
           <span><a href={`mailto:${mail}`} className="uk-link-reset">{mail}</a></span>
        </div>
    )
}
