import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navItems } from '../data';

export default function NavbarItems() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className='uk-navbar-nav'>
      {navItems.map((item, index) => (
        <div
          key={index}
          className="uk-navbar-item uk-link-reset"
          style={{
            backgroundColor: isActive(item.path) ? '#f0f0f0' : 'transparent', 
          }}
        >
          <Link to={item.path}  style={{}}>{item.name}</Link>
        </div>
      ))}
    </div>
  );
}
