import React from 'react'
import ContactLocationsHeader from './ContactLocations/ContactLocationsHeader'

export default function ContactLocations() {
    return (
        <>
            <ContactLocationsHeader/>
        </>
    )
}
