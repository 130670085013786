import React from "react";
import { projects } from "../data";

export default function ResearchContent() {
  return (
    <section className="uk-section uk-padding-remove-top">
      <div className="uk-container uk-container-large">
        <div className="uk-card  uk-card-hover uk-card-body uk-width-1-1@m uk-card-primary uk-flex uk-flex-center uk-flex-middle uk-text-center uk-margin-medium-top">
          <div className="uk-width-1-1">
            <h3 className="uk-card-title">PATENT</h3>
            <p className="text-center uk-margin-medium-top">
              <a
                href="https://patents.google.com/patent/US20230131584A1/en"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                "Multi-tone continuous wave detection and ranging." U.S. Patent
                Application No. 17/467,012.{" "}
              </a>
            </p>
          </div>{" "}
        </div>
        <div
          className="uk-grid-large uk-grid-match uk-child-width-1-2@s uk-child-width-1-4@m"
          data-uk-grid
        >
          {projects.map((item, index) => (
            <div className="uk-card uk-card-hover uk-card-body uk-width-1-2@m" key={index}>
              <h3 className="uk-card-title">{item.title}</h3>

              <div uk-slider="clsActivated: uk-transition-active; center: true">
                <div
                  class="uk-position-relative uk-visible-toggle uk-light"
                  tabindex="-1"
                  uk-slider="center: true"
                >
                  <div class="uk-slider-items uk-grid">
                    {item.images.map((image, index) => (
                      <div class="uk-width-3-4" key={index}>
                        <div class="uk-panel">
                        <img
                            src={image}
                            uk-cover
                            alt={`Slide ${index + 1}`} 
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <div className="uk-position-center uk-panel">
                            <h1>{index + 1}</h1>
                          </div>
                        </div>
                      </div>
                    ))}

                   
                  </div>

                  <a
                    className="uk-position-center-left uk-hidden-hover uk-slidenav-large uk-slidenav-dark uk-light"
                    href="#"
                    data-uk-slidenav-previous
                    uk-slider-item="previous"
                    style={{color:"black"}}
                  ></a>
                  <a
                    className="uk-position-center-right  uk-hidden-hover uk-slidenav-large uk-slidenav-dark uk-light"
                    href="#"
                    data-uk-slidenav-next
                    uk-slider-item="next"
                    style={{color:"black"}}
                  ></a>
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
              </div>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
