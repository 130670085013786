import React from "react";
import { education } from "../data";

export default function AboutEducation() {
  return (
    <section className="uk-section uk-background-muted">
      <div className="uk-container">
        <div
          className="uk-margin-auto uk-flex-center uk-text-center uk-width-1-2@m uk-grid-collapse"
          data-uk-grid
        >
          <div className="uk-h2">Education</div>
        </div>
      </div>
      <div className="uk-container uk-container-small uk-section">
        <div className="uk-child-width-1-1 uk-text-center" data-uk-grid>
          {education.map((item, index) => (
            <div>
              <div className="bottom-line">
                <div className="uk-h4">{item.school}</div>
                <div>{item.dateRange}</div>
                <div className="uk-h4 uk-margin-top">{item.department}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
