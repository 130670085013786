import React from "react";
import { work } from "../data";

export default function AboutMyWorks() {
  const renderDescriptionDetail = (descriptions) => {
    return descriptions.map((item, index) => (
      <li key={index}>
        {item.link ? (
          <a href={item.link} target="_blank" rel="noopener noreferrer">{item.description}</a>
        ) : (
          <>{item.description}</>
        )}
        {item.children.length > 0 && (
          <ul>{renderDescriptionDetail(item.children)}</ul>
        )}
      </li>
    ));
  };

  return (
    <section className="uk-section threatments-content uk-padding-remove-top">
      <div className="uk-container">
        <div className="uk-child-width-1-1@m cards uk-grid-large" data-uk-grid>
          {work.map((item, index) => (
            <div className="uk-card  uk-card-hover uk-card-body uk-width-1-1@m" key={index}>
              <div className="uk-child-width-1-2@m uk-flex-middle" data-uk-grid>
                <div>
                  {" "}
                  <a href={item.companyLink} target="_blank" rel="noopener noreferrer">
                    <div className="uk-flex uk-flex-center">
                      <img
                        src={item.companyImage}
                        alt={item.companyName}
                        loading="lazy"
                        className="uk-width-1-2 uk-height-auto"
                      ></img>
                    </div>
                  </a>
                </div>

                <div>
                  <h3 className="uk-margin-remove-top uk-margin-remove-bottom">
                    {item.companyName} – {item.title}
                  </h3>
                  <div className="date uk-margin-small-bottom">{item.date}</div>
                  <div className="desc">
                    <ul>{renderDescriptionDetail(item.descriptionDetail)}</ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
