import React from 'react'

export default function ContactIntroduction() {
    return (
        <section className="uk-section">
        
        <div className="uk-grid-collapse uk-flex-middle uk-flex-center" data-uk-grid>
   
             
                    <div className="uk-h2">
                  CONTACT
                    </div>
      
        </div>

</section>
    )
}
