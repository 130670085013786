import { Routes, Route, useLocation} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import HomeScreen from './pages/HomeScreen';
import WorkScreen from './pages/WorkScreen';
import ResearchScreen from './pages/ResearchScreen';
import ContactScreen from './pages/ContactScreen';
import Footer from './components/Footer/Footer';
import PublicationsScreen from './pages/PublicationsScreen';
import { useEffect } from 'react';

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  return (
    <>
      <Navbar/>
      <ScrollToTop/>
      <main>
        <Routes>
          <Route exact path="/" element={<HomeScreen/>}/>
          <Route path="/workexperience" element={<WorkScreen/>}/>
          <Route path="/researchprojects&teachingexperience" element={<ResearchScreen/>}/>
          <Route path="/contact" element={<ContactScreen/>}/>
          <Route path="/publications" element={<PublicationsScreen/>}/>
        </Routes>
      </main>
      <Footer/>
    </>
  );
}

export default App;
