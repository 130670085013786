import React from 'react'
import PublicationsIntroduction from "../components/Publications/PublicationsIntroduction";
import PublicationsContent from '../components/Publications/PublicationsContent';
import ConferencesIntroduction from '../components/Publications/ConferencesIntroduction';
import ConferencesContent from '../components/Publications/ConferencesContent';

export default function PublicationsScreen() {
    return (
        <>

<div>
        <div uk-grid>
          <div className="uk-width-auto@m uk-flex-last@m uk-flex uk-flex-center uk-top uk-margin-large-top ">
            <ul
              className="uk-tab "
              uk-tab="connect: #component-tab; animation: uk-animation-fade" style={{ fontSize: '2rem' }} 
            >
              <li>
                <a href="#" style={{ fontSize: "18px" }}>Journals</a>
              </li>
              <li>
                <a href="#" style={{ fontSize: "18px" }}>Conferences</a>
              </li>
            </ul>
          </div>
          <div className="uk-width-expand@m">
            <div id="component-tab" className="uk-switcher">
              <div>
              <PublicationsIntroduction/>
              <PublicationsContent/>
              </div>
              <div>
                <ConferencesIntroduction/>
                <ConferencesContent/>
                {/* <TeachingExperience />
                <TeachingExperienceContent /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
       
        </>
    )
}
