import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import UIkit from 'uikit';

export default function ContactForm() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_0qg27ij', 'template_uoyz5sq', form.current, '7SFUjGgzYAzEYyxXF')
            .then(
                () => {
                    UIkit.notification({ message: 'Form sent successfully.', status: 'success', pos: 'top-right' });
                    form.current.reset();
                },
                (error) => {
                    UIkit.notification({ message: 'Failed to send the form.', status: 'danger', pos: 'top-right' });
                }
            );
    };

    return (
        <section className="uk-section uk-background-primary">
            <div className="uk-container uk-section uk-padding-remove-top">
                <div className="uk-width-1-2@m uk-margin-auto uk-grid-collapse uk-flex-center" data-uk-grid>
                    <div className="uk-h2 uk-light">Get in Touch</div>
                </div>
            </div>
            <div className="uk-container">
                <div className="uk-width-3-5@m uk-margin-auto">
                    <div className="uk-child-width-1-1" data-uk-grid>
                        <div>
                            <form className="uk-grid-small" data-uk-grid ref={form} onSubmit={sendEmail}>
                                <div className="uk-width-1-1">
                                    <label className="uk-form-label" htmlFor="name">Name & Surname*</label>
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon" uk-icon="icon: user"></span>
                                        <input className="uk-input uk-light" type="text" placeholder="Enter your name and surname" id="name" name="name" required />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <label className="uk-form-label" htmlFor="email">Email*</label>
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon" uk-icon="icon: mail"></span>
                                        <input className="uk-input" type="email" placeholder="Enter your email address" id="email" name="email" required />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <label className="uk-form-label" htmlFor="company">Company Name (optional)</label>
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon" uk-icon="icon: world"></span>
                                        <input className="uk-input" type="text" placeholder="Enter your company name" id="company" name="company" />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <label className="uk-form-label" htmlFor="subject">Subject*</label>
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon" uk-icon="icon: quote-right"></span>
                                        <input className="uk-input" type="text" placeholder="Enter your subject" id="subject" name="subject" required />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <label className="uk-form-label" htmlFor="message">Message*</label>
                                    <textarea className="uk-textarea resize" rows="5" placeholder="Enter your message" id="message" name="message" required></textarea>
                                </div>
                                <div className="uk-width-1-1 uk-flex-center uk-flex">
                                    <button className="uk-button uk-button-default uk-border-rounded" type="submit">Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
