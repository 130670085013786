import React from 'react'
import AboutIntroduction from "../components/About/AboutIntroduction";
import AboutMyWorks from '../components/About/AboutMyWorks';

export default function WorkScreen() {
    return (
        <>
            <AboutIntroduction/>
            <AboutMyWorks/>
        </>
    )
}
