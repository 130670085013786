import React from "react";
import { awards } from "../data";

export default function Awards() {
  return (
    <section className="uk-section uk-background-muted">
      <div className="uk-container">
        <div
          className="uk-margin-auto uk-flex-center uk-text-center uk-width-1-2@m uk-grid-collapse"
          data-uk-grid
        >
          <div className="uk-h2">Awards</div>
        </div>
      </div>

      <div className="uk-container uk-section">
        <div
          className="uk-flex uk-flex-center uk-flex-wrap uk-grid-small uk-child-width-1-2@m uk-child-width-1-4@l uk-text-center"
          data-uk-grid
        >
          {awards.map((item, index) => (
            <div key={index}>
              <div className="uk-card uk-card-default uk-card-body">
                <div className="uk-h4">{item.award}</div>
                <div>{item.dateRange}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
