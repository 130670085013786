import React from "react";
import { conferences } from "../data";

export default function ConferencesContent() {
  return (
    <section className="uk-section uk-padding-remove-top">
      <div className="uk-container uk-container-large">
        <div
          className="uk-grid-large uk-grid-match uk-child-width-1-2@s uk-child-width-1-4@m "
          data-uk-grid
       
        >
          {conferences.map(( item, index) => (
            <div
              key={index}
              className="uk-card uk-width-1-2@m"
            >
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <h3 className="uk-text-small">{item.title}</h3>
                <p className="uk-text-small">{item.authors}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
