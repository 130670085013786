import React from 'react'
import articlesIntro from "../../assets/ab-mtcw-4.jpg";

export default function ResearchIntroduction() {
    return (
        <section className="uk-section">
            <div className="uk-container uk-container-small">
                <div className="uk-grid-collapse uk-flex-middle uk-flex-center" data-uk-grid>
                    <div className="uk-width-3-4@m">
                        <img src={articlesIntro} alt="" className="uk-width-1-1" data-uk-img/>
                    </div>
                    <div className="uk-width-1-4@m">
                        <div className="uk-tile uk-tile-primary uk-padding-large collapse-margin">
                            <div className="uk-h2">
                                Research Projects 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}