import React from "react";
import { teachingExperience } from "../data";

export default function TeachingExperienceContent() {

  const renderDescriptionDetail = (descriptions) => {
    return descriptions.map((item, index) => (
      <li key={index}>
        <p>{item.description}</p>
        {item.children.length > 0 && (
          <ul>{renderDescriptionDetail(item.children)}</ul>
        )}
      </li>
    ));
  };
  return (
    <section className="uk-section uk-padding-remove-top uk-padding-remove-top">
      <div className="uk-container uk-container-large">
        <div
          className="uk-grid-large uk-grid-match uk-child-width-1-2@s uk-child-width-1-4@m"
          data-uk-grid
          data-uk-lightbox
        >

        {teachingExperience.map((item,index) => 
        
        <div className="uk-card uk-card-hover uk-card-body uk-width-1-1@m uk-margin-remove-bottom uk-background-muted" key={index}>
            <h3 className="uk-margin-remove-top uk-margin-remove-bottom">
              {item.schoolName}
            </h3>
            <h4 className="uk-margin-small-top">{item.title} </h4>
            <div className="date uk-margin-small-bottom">
              {item.date}
            </div>
            <div className="desc">
            {renderDescriptionDetail(item.descriptionDetail)}
            </div>
          </div>)}
          
        </div>
      </div>
      <div className="uk-card  uk-card-hover uk-card-body uk-width-1-1@m uk-card-primary uk-flex uk-flex-center uk-flex-middle uk-text-center uk-margin-medium-top">
        <div className="uk-width-1-1">
          <h3 className="uk-card-title">PATENT</h3>
          <p className="text-center uk-margin-medium-top">
            <a
              href="https://patents.google.com/patent/US20230131584A1/en"
              target="_blank"
              rel="noopener noreferrer"
            >
      
              "Multi-tone continuous wave detection and ranging." U.S. Patent
              Application No. 17/467,012.
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
